import {
  LoadingOverlay,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Image,
  Loader,
  Select,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { FaCalendarDays } from "react-icons/fa6";
import { FaMapMarkerAlt } from "react-icons/fa";
import { LuChevronDown } from "react-icons/lu";
import { MdHideImage } from "react-icons/md";

export const AttendanceCard = ({
  loading,
  loadingWindow,
  GlobalCard,
  StepperComponent,
  classes,
  form,
  useWindowSize,
  handleClick,
  handleError,
  utils,
  errorImages,
  orderedList,
  error,
  handleSubmit,
  data,
}) => {
  const isMd = useWindowSize("md");

  if (loadingWindow) {
    return (
      <LoadingOverlay
        visible={loadingWindow}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return (
    <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
      <StepperComponent servicesData={data?.travelLiquidatorData} />

      <GlobalCard>
        <Text fw={700} fz={24} color="#004236" my={12}>
          Buscar asistencia médica
        </Text>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col span={12}>
              <Select
                {...form.getInputProps("origin")}
                label="Destino (Continente)"
                classNames={{
                  dropdown: classes.dropdown,
                  rightSection: classes.rightSection,
                  label: classes.labelSecondary,
                  input: classes.input,
                  item: classes.item,
                }}
                data={data?.continents}
                rightSection={<LuChevronDown size="1rem" color="#004236" />}
                icon={<FaMapMarkerAlt size="1rem" color="#004236" />}
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <DatePickerInput
                {...form.getInputProps("startDate")}
                label="Fecha de inicio"
                locale={utils.es}
                rightSection={<LuChevronDown size="1rem" color="#004236" />}
                classNames={{
                  label: classes.labelSecondary,
                  input: classes.input,
                  day: classes.calendar,
                  rightSection: classes.rightSection,
                }}
                valueFormat="DD-MMM-YYYY"
                icon={<FaCalendarDays size="1rem" color="#004236" />}
                minDate={new Date()}
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <DatePickerInput
                {...form.getInputProps("endDate")}
                label="Fecha final"
                locale={utils.es}
                rightSection={<LuChevronDown size="1rem" color="#004236" />}
                classNames={{
                  label: classes.labelSecondary,
                  input: classes.input,
                  day: classes.calendar,
                  rightSection: classes.rightSection,
                }}
                valueFormat="DD-MMM-YYYY"
                icon={<FaCalendarDays size="1rem" color="#004236" />}
                minDate={form.values.startDate || new Date()}
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <DatePickerInput
                {...form.getInputProps("birthDate")}
                label="Fecha de nacimiento"
                locale={utils.es}
                rightSection={<LuChevronDown size="1rem" color="#004236" />}
                classNames={{
                  label: classes.labelSecondary,
                  input: classes.input,
                  day: classes.calendar,
                  rightSection: classes.rightSection,
                }}
                valueFormat="DD-MMM-YYYY"
                icon={<FaCalendarDays size="1rem" color="#004236" />}
                withAsterisk
              />
            </Grid.Col>

            <Grid.Col offsetMd={6} xs={3}>
              <Button
                fullWidth
                className={classes.button}
                type="submit"
                disabled={!form.isValid()}
              >
                Buscar
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                className={classes.buttonOutline}
                data-testid="skip-action-button"
                onClick={() => handleClick({})}
              >
                Trayecto sin Asistencia
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </GlobalCard>
      {loading ? (
        <GlobalCard>
          <Flex
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
            mih={isMd ? "auto" : "224px"}
          >
            <Text size="md" fw={700}>
              Cargando servicios de asistencia médica disponibles...
            </Text>
            <Loader variant="dots" color="#cbd300" />
          </Flex>
        </GlobalCard>
      ) : error ? (
        <GlobalCard>
          <Flex
            gap={12}
            justify="center"
            align="center"
            direction="column"
            sx={{ height: isMd ? "auto" : "224px" }}
          >
            <Text fw={700} color="#004236" ta="center">
              {`Con los parámetros seleccionados no se encontró servicios de asistencia médica en ${
                form.values.origin.split("/")[1]
              }.`}
            </Text>
          </Flex>
        </GlobalCard>
      ) : (
        orderedList.map((service, index) => (
          <GlobalCard key={index}>
            <Grid sx={{ height: isMd ? "auto" : "224px" }} align="center">
              <Grid.Col md={3} xs={12}>
                {errorImages[index] !== false ? (
                  <Image
                    radius="md"
                    height={180}
                    src={service.image}
                    onError={() => handleError(index)}
                  />
                ) : (
                  <Flex align="center" justify="center" h="100%">
                    <MdHideImage size="2rem" color="#004236" />
                  </Flex>
                )}
                <Text fw={700} color="#004236" ta="center">
                  {service.nameCompany}
                </Text>
              </Grid.Col>
              <Grid.Col
                xs={12}
                md={6}
                sx={(theme) => ({
                  [theme.fn.smallerThan("md")]: {
                    textAlign: "center",
                  },
                })}
              >
                <Text fw={700} size="lg">
                  {service.name}
                </Text>
                <Text>
                  Monto máximo de cobertura:
                  {utils.formatter.format(service.maxAmount)}
                </Text>
                <Text>Servicios</Text>
                <Flex
                  gap={12}
                  sx={(theme) => ({
                    [theme.fn.smallerThan("md")]: {
                      justifyContent: "center",
                    },
                  })}
                >
                  {service.servicios.map((item, index) => (
                    <Box key={index}>
                      <Image
                        src={item.value}
                        alt={`Servicio ${++index} de ${service.name}`}
                        height={50}
                        fit="contain"
                      />
                      <Text fw={700} mt={8}>
                        {item.key}
                      </Text>
                    </Box>
                  ))}
                </Flex>
              </Grid.Col>
              <Divider orientation="vertical" />
              <Grid.Col xs={12} md="auto">
                <Text ta="center" fw={700} fz="lg">
                  Tarifa por número de días de cobertura
                </Text>
                <Text ta="center" fw={700} mt={12}>
                  Tarifa total
                </Text>
                <Text fw={700} fz="lg" ta="center" color="#004236">
                  {service.fares.currency === "COP" &&
                    utils.formatter.format(service.fares.totalAmount)}
                </Text>
                <Button
                  fullWidth
                  className={classes.button}
                  mt={16}
                  onClick={() => handleClick(service)}
                  data-testid="action-button"
                >
                  Seleccionar
                </Button>
              </Grid.Col>
            </Grid>
          </GlobalCard>
        ))
      )}
    </Box>
  );
};
