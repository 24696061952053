import {
  GlobalCard,
  StepperComponent,
  useStyles,
  useWindowSize,
} from "@mfe/js-common-ave-uiutils";
import { useForm } from "@mantine/form";

import {
  MsalProvider,
  useIsAuthenticated,
  useMsal,
} from "@mfe/react-common-ave-msal";

import {
  es,
  fetchAttendanceData,
  fetchAttendanceReservationDetail,
  fetchContinentsData,
  fetchTraveLiquidatorByIDData,
  fetchTraveLiquidatorUpdateData,
  filterEmpty,
  formatter,
  getAccessTokenApi,
  lodash,
  parseDateWihtFormat,
} from "@mfe/ts-common-ave-utilitaries";
import { useEffect, useState } from "react";
import { AttendanceCard } from "../components/attendanceCard.component";

export default function BookAttendance({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <BookAttendanceComponent organization={organization} />
    </MsalProvider>
  );
}

export const BookAttendanceComponent = ({ organization }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { classes } = useStyles();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [tokenApi, setTokenApi] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [travelIndex, setTravelIndex] = useState(0);
  const [attendancesSelected, setAttendancesSelected] = useState([]);
  const [finalAttendance, setFinalAttendance] = useState(false);
  const [loadingWindow, setLoadingWindow] = useState(false);
  const [errorImages, setErrorImages] = useState([]);
  const [data, setData] = useState({
    continents: [],
    attendanceList: [],
    travelLiquidatorData: { ViajeTrayecto: [], Multiviaje: "" },
  });

  const form = useForm({
    initialValues: {
      origin: "",
      startDate: null,
      endDate: null,
      birthDate: null,
    },
    validate: {
      origin: (value) => (value ? null : "Campo obligatorio"),
      startDate: (value) => (value ? null : "Campo obligatorio"),
      endDate: (value) => (value ? null : "Campo obligatorio"),
      birthDate: (value) => (value ? null : "Campo obligatorio"),
    },
  });
  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchContinentsData(tokenApi, setData);
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoadingWindow
      );
    }
  }, [tokenApi]);

  useEffect(() => {
    const indice = nextAttendanceMap
      .map((viaje) => viaje.ServicioBpo)
      .findIndex((servicios) =>
        servicios.some((s) => s.AliasServicio === "ASISMED")
      );
    setTravelIndex(indice);
  }, [data?.travelLiquidatorData]);

  const handleSubmit = (values) => {
    setError(false);
    fetchAttendanceData(tokenApi, setData, setLoading, setError, {
      origin: values.origin.split("/")[0],
      departureDate: parseDateWihtFormat(values.startDate, "YYYY-MM-DD"),
      returnDate: parseDateWihtFormat(values.endDate, "YYYY-MM-DD"),
      birthDate: parseDateWihtFormat(values.birthDate, "YYYY-MM-DD"),
      userData,
    });
  };

  const nextAttendanceMap = data?.travelLiquidatorData?.ViajeTrayecto?.filter(
    (trip) =>
      trip?.ServicioBpo?.some((service) => service.AliasServicio === "ASISMED")
  );

  const updateOriginalList = (original, modified) => {
    modified.forEach((modificado) => {
      const indice = original.findIndex(
        (original) => original.Id === modificado.Id
      );

      if (indice !== -1) {
        original[indice] = modificado;
      }
    });

    return original;
  };

  const handleClick = async (item) => {
    if (!!Object.keys(item).length) {
      await fetchAttendanceReservationDetail(tokenApi, {
        attendance: item,
        travel: nextAttendanceMap[travelIndex],
        price: data?.travelLiquidatorData,
        higherRate: Math.max(
          ...data?.attendanceList?.map((att) =>
            parseFloat(att.fares.totalAmount)
          )
        ),
        lowerRate: Math.min(
          ...data?.attendanceList?.map((att) =>
            parseFloat(att.fares.totalAmount)
          )
        ),
      });
    }

    const newAttendanceMap = [...nextAttendanceMap];
    const validateEmpty = !Object.keys(item).length;

    const serviceIndex = newAttendanceMap[travelIndex].ServicioBpo?.findIndex(
      (servIndex) => servIndex.AliasServicio === "ASISMED"
    );
    validateEmpty
      ? (newAttendanceMap[travelIndex].ServicioBpo[serviceIndex] = filterEmpty({
          ...newAttendanceMap[travelIndex].ServicioBpo[serviceIndex],
        }))
      : (newAttendanceMap[travelIndex].ServicioBpo[serviceIndex] = {
          ...newAttendanceMap[travelIndex].ServicioBpo[serviceIndex],
          Valor: item?.fares?.totalAmount,
          nombreServicio: item?.nameCompany,
          descripcionServicio: JSON.stringify({
            ...item,
            isCheapest:
              lodash.orderBy(
                data?.attendanceList,
                [(obj) => parseFloat(obj?.fares?.totalAmount)],
                ["asc"]
              )?.[0]?.fares?.totalAmount === item?.fares?.totalAmount,
            initialDate: form.values.startDate,
            finalDate: form.values.endDate,
          }),
          imagenServicio: item?.image,
        });

    setAttendancesSelected(newAttendanceMap);

    const remainingIndexes = newAttendanceMap
      .slice(travelIndex + 1)
      .findIndex((servIndex) =>
        servIndex.ServicioBpo.some(
          (service) => service.AliasServicio === "ASISMED"
        )
      );

    if (remainingIndexes !== -1) {
      setTravelIndex(travelIndex + 1);
      form.reset();
      setData((prev) => ({
        ...prev,
        attendanceList: [],
      }));
    } else {
      setFinalAttendance(true);
    }
  };

  const handleError = (index) => {
    const newImages = [...errorImages];
    newImages[index] = false;
    setErrorImages(newImages);
  };

  useEffect(() => {
    if (finalAttendance) {
      fetchTraveLiquidatorUpdateData(
        tokenApi,
        {
          ...data?.travelLiquidatorData,
          ViajeTrayecto: updateOriginalList(
            data?.travelLiquidatorData?.ViajeTrayecto,
            attendancesSelected
          ),
        },
        setLoadingWindow
      );
    }
  }, [finalAttendance]);

  const orderedList =
    lodash.orderBy(
      data?.attendanceList,
      [(obj) => parseFloat(obj?.fares?.totalAmount)],
      ["des"]
    ) || [];

  const props = {
    loading,
    loadingWindow,
    GlobalCard,
    StepperComponent,
    classes,
    form,
    useWindowSize,
    handleClick,
    handleError,
    utils: { es, formatter },
    errorImages,
    orderedList,
    error,
    handleSubmit,
    data,
  };

  return isAuthenticated ? <AttendanceCard {...props} /> : null;
};
